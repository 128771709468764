import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/react'

import WhoToTest from './WhoToTest'
import PatientCount from './PatientCount'
import Calendar from './Calendar'
import Otp from './Otp'
import ExistingAppointments from './ExistingAppointments'
import UserForm from './UserForm'
import PatientForm from './PatientForm'
import Confirmation from './Confirmation'
import { FirstAvailableSlot } from './FirstAvailableSlot'
import DropinAppointmentSearch from './DropinAppointmentSearch'
import LanguageSwitcher from '../forms/fields/LanguageSwitcher'
import AppointmentSummary from './AppointmentSummary'
import ShieldedSpinner from '../ShieldedSpinner'
import ErrorView from './ErrorView'
import ConsentOverview from './ConsentOverview'
import WhoIsPaying from './WhoIsPaying'

import {
  createAppointment,
  fetchAppointment,
  fetchUpcomingAppointments,
  updateAppointment,
  logOut,
  confirmAppointment,
} from './queries'

import { DEFAULT_LOCALE, translate } from '../../lib/locale'
import { serviceCenterShape } from './propShapes'
import { ProductSelector } from './extra_clinic_services/ProductSelector'
import { ServiceCenterSelector } from './extra_clinic_services/ServiceCenterSelector'
import ConsentForm from './ConsentForm'

const VIEWS = {
  selectProduct: 'selectProduct',
  selectServiceCenter: 'selectServiceCenter',
  who: 'who',
  patientCount: 'patientCount',
  firstAvailable: 'firstAvailable',
  dropinSearch: 'dropinSearch',
  calendar: 'calendar',
  otp: 'otp',
  existingAppointment: 'existingAppointment',
  user: 'user',
  WhoIsPaying: 'whoIsPaying',
  patients: 'patients',
  confirmation: 'confirmation',
  consent: 'consent',
}

export const WHO = {
  me: 'me',
  meplus: 'meplus',
  others: 'others',
}

export const MODE = {
  NORMAL: 'NORMAL',
  DROP_IN_NOW: 'DROP_IN_NOW',
  DROP_IN_NEXT_AVAILABLE: 'DROP_IN_NEXT_AVAILABLE',
}

let existingAppointments
let patientCount
let patients
let user
let who
let dropinMode

const resetNonReactiveState = () => {
  existingAppointments = null
  patientCount = null
  patients = []
  user = null
  who = null
}

resetNonReactiveState()

const AppointmentWizard = ({
  serviceCenter,
  booking,
  billableOrganizations,
  phoneNumber,
  productSlugs,
  organization,
  kioskMode,
  assetRootUrl,
  appointmentPatient,
  linkAppointment,
}) => {
  const hasProduct = () => {
    return (
      productSlugs && productSlugs.length && productSlugs.length > 0
    )
  }

  const hasProductAndServiceCenter = () => {
    return serviceCenter && hasProduct()
  }

  const getDefaultView = () => {
    switch (true) {
      case !!booking:
        return VIEWS.calendar
      case !!appointmentPatient:
        return VIEWS.consent
      case hasProductAndServiceCenter():
        return VIEWS.who
      case hasProduct():
        return VIEWS.selectServiceCenter
      default:
        return VIEWS.selectProduct
    }
  }

  const DEFAULT_VIEW = getDefaultView()

  dropinMode = serviceCenter?.operation_type === 'mobile' || !!booking

  const logOutUser = async () => {
    await logOut()
  }

  const reset = () => {
    resetNonReactiveState()
    setView(DEFAULT_VIEW)
    setAppointment({
      serviceCenter: serviceCenter,
      booking: booking,
      organization: organization,
      productSlugs: productSlugs,
    })
    setLoading(null)
    setError(null)
    setUpdateMode(false)
    if (kioskMode) {
      logOutUser()
    }
  }

  const hasMultipleCustomers = !!booking && booking.multiple_customers
  const comingFromSmsLink = linkAppointment ? true : false
  const [locale, setLocale] = useState(DEFAULT_LOCALE)
  const [view, setView] = useState(DEFAULT_VIEW)
  const [loading, setLoading] = useState(null)
  const [slotTaken, setSlotTaken] = useState(false)
  const [appointment, setAppointment] = useState({
    serviceCenter: serviceCenter,
    booking: booking,
    productSlugs: productSlugs,
  })
  const [error, setError] = useState(null)
  const [updateMode, setUpdateMode] = useState(false)
  const [companyName, setCompanyName] = useState(null)
  const [whoIsPaying, setWhoIsPaying] = useState(null)

  useEffect(() => {
    const fetchSameAppointment = async (appointment) => {
      const newAppointment = await fetchAppointment(appointment)
      setAppointment(newAppointment)
    }
    if (!linkAppointment) {
      return
    } else {
      fetchSameAppointment(linkAppointment)
    }
  }, [linkAppointment])

  useEffect(() => {
    if (!!serviceCenter || !!appointment?.serviceCenter) return
    who = WHO.me
  }, [serviceCenter, appointment])

  const t = (prop, source, transform, vars) =>
    translate(`${locale}.${prop}`, source, transform, vars)

  const showLoadingShield = (status, message) => {
    setLoading({ status, message })
  }

  const modeTimeSelectView = () => {
    if (dropinMode) return VIEWS.dropinSearch
    if (kioskMode) return VIEWS.firstAvailable
    return VIEWS.calendar
  }

  const showError = (error) => {
    //console.error(error)
    if (
      error.message.includes(
        'There is an appointment matching same criteria',
      )
    ) {
      setView(VIEWS.existingAppointment)
      return
    } else {
      Sentry.captureException(error, {
        extra: {
          user,
          appointment,
          patients,
          view,
          existingAppointments,
          patientCount,
          who,
          dropinMode,
          kioskMode,
        },
      })
      setError(error)
      setView(VIEWS.error)
    }
  }

  const trySubmit = async (date = null, hour = null) => {
    try {
      setSlotTaken(false)
      const newAppointment =
        date && hour
          ? {
              ...appointment,
              date,
              hour,
            }
          : { ...appointment }

      const response = updateMode
        ? await updateAppointment(user, newAppointment)
        : await createAppointment(
            newAppointment,
            patients,
            hasMultipleCustomers,
            companyName,
            whoIsPaying,
          )
      if (
        response.errors &&
        response.errors.message === 'Timeslot not available'
      ) {
        setSlotTaken(true)
        setView(VIEWS.calendar)
        return false
      } else if (response.netError) {
        showError(response.errors)
        return false
      } else {
        setAppointment({
          ...response.data,
          productSlugs: response.data.products.map((p) => p.slug),
        })
        return true
      }
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          location: 'AppointmentWizard.trySubmit',
        },
      })
      setError(e.toString())
      return false
    }
  }

  const onUpdate = () => {
    setUpdateMode(true)
    setView(VIEWS.calendar)
  }

  const handleWhoIsPaying = (employer, payee) => {
    setCompanyName(employer)
    setWhoIsPaying(payee)
  }

  const onConfirmAppointment = async () => {
    const appointmentId = appointment.id
    try {
      await confirmAppointment(appointmentId)
    } catch (e) {
      console.error(e)
      Sentry.captureException(e, {
        extra: {
          location: 'AppointmentWizard.onConfirmAppointment',
        },
      })
    }
  }

  const onStepResult = async (result) => {
    // console.info(view + ':', result, appointment)
    switch (view) {
      case VIEWS.selectProduct: {
        setAppointment((prev) => {
          return {
            ...prev,
            productSlugs: result.map((p) => p.slug),
            products: result,
          }
        })
        if (!appointment.serviceCenter) {
          setView(VIEWS.selectServiceCenter)
        } else {
          setView(VIEWS.who)
        }
        break
      }
      case VIEWS.selectServiceCenter: {
        setAppointment((prev) => {
          return { ...prev, serviceCenter: result }
        })
        setView(VIEWS.who)
        break
      }
      case VIEWS.who:
        if (result === WHO.me) {
          who = result
          patientCount = 1
          setView(modeTimeSelectView())
        } else {
          who = result
          setView(VIEWS.patientCount)
        }
        break

      case VIEWS.patientCount:
        patientCount = result
        setView(modeTimeSelectView())
        break

      case VIEWS.dropinSearch:
        setAppointment({
          ...appointment,
          date: result.date,
          hour: result.hour,
        })
        setView(VIEWS.otp)
        break

      case VIEWS.firstAvailable:
        if (result === null) {
          setView(VIEWS.calendar)
        } else {
          setAppointment({
            ...appointment,
            date: result.date,
            hour: result.hour,
          })
          setView(VIEWS.otp)
        }
        break

      case VIEWS.calendar:
        if (user) {
          if (await trySubmit(result.date, result.hour)) {
            setView(VIEWS.confirmation)
          }
        } else {
          setAppointment({
            ...appointment,
            date: result.date,
            hour: result.hour,
          })
          setView(VIEWS.otp)
        }
        break

      case VIEWS.otp:
        user = { ...result.user, id: parseInt(result.user.id) }
        try {
          const response = await fetchUpcomingAppointments(user)
          if (response.data.length) {
            existingAppointments = response.data
            setView(VIEWS.existingAppointment)
          } else if (hasMultipleCustomers) {
            setView(VIEWS.WhoIsPaying)
          } else {
            setView(VIEWS.user)
          }
        } catch (e) {
          setView(VIEWS.user)
        }

        break

      case VIEWS.existingAppointment:
        if (hasMultipleCustomers) {
          setView(VIEWS.WhoIsPaying)
        } else {
          setView(VIEWS.user)
        }
        break

      case VIEWS.WhoIsPaying:
        setView(VIEWS.user)
        break

      case VIEWS.user:
        if (who === WHO.me || who === WHO.meplus) {
          patients = result.companyName
            ? [
                {
                  id: result.patient.id,
                  companyName: result.companyName,
                },
              ]
            : [result.patient.id]
        }
        if (who === WHO.me) {
          if (await trySubmit()) {
            if (
              appointment.productSlugs.some((slug) =>
                slug.includes('vac'),
              )
            ) {
              setView(VIEWS.consent)
            } else {
              setView(VIEWS.confirmation)
            }
          }
        } else setView(VIEWS.patients)
        break

      case VIEWS.patients:
        patients = [...patients, ...result]
        if (await trySubmit()) {
          if (
            appointment.productSlugs.some((slug) =>
              slug.includes('vac'),
            )
          ) {
            setView(VIEWS.consent)
          } else {
            setView(VIEWS.confirmation)
          }
        }
        break
      case VIEWS.consent:
        await onConfirmAppointment()
        setView(VIEWS.confirmation)
        break
      default:
        return `Cannot handle result from ${view}: ${result}`
    }
  }

  const renderView = () => {
    switch (view) {
      case VIEWS.selectProduct:
        return (
          <ProductSelector
            serviceCenter={appointment.serviceCenter}
            onComplete={onStepResult}
            onError={showError}
            t={t}
          />
        )
      case VIEWS.selectServiceCenter:
        return (
          <ServiceCenterSelector
            renderAppointmentSummary={renderAppointmentSummary}
            onComplete={onStepResult}
            productSlugs={appointment.productSlugs}
            onError={showError}
            showReset={DEFAULT_VIEW !== VIEWS.selectServiceCenter}
            reset={reset}
            t={t}
          />
        )
      case VIEWS.who:
        return (
          <WhoToTest
            renderAppointmentSummary={renderAppointmentSummary}
            maxCapacity={
              appointment.serviceCenter.maxCapacity ||
              appointment.serviceCenter.max_capacity
            }
            onComplete={onStepResult}
            t={t}
            showReset={DEFAULT_VIEW !== VIEWS.who}
            reset={reset}
          />
        )

      case VIEWS.patientCount:
        return (
          <PatientCount
            who={who}
            renderAppointmentSummary={renderAppointmentSummary}
            maxCapacity={appointment.serviceCenter.maxCapacity}
            onComplete={onStepResult}
            reset={reset}
            t={t}
          />
        )

      case VIEWS.firstAvailable:
        return (
          <FirstAvailableSlot
            serviceCenter={appointment.serviceCenter}
            booking={booking}
            renderAppointmentSummary={renderAppointmentSummary}
            productSlugs={appointment.productSlugs}
            patientCount={patientCount}
            onComplete={onStepResult}
            setLoading={showLoadingShield}
            onError={showError}
            reset={reset}
            t={t}
          />
        )

      case VIEWS.dropinSearch:
        return (
          <DropinAppointmentSearch
            serviceCenter={appointment.serviceCenter}
            patientCount={patientCount}
            productSlugs={appointment.productSlugs}
            onComplete={onStepResult}
            setLoading={showLoadingShield}
            onError={showError}
            reset={reset}
            t={t}
          />
        )

      case VIEWS.calendar:
        return (
          <Calendar
            renderAppointmentSummary={renderAppointmentSummary}
            serviceCenter={appointment.serviceCenter}
            booking={booking}
            patientCount={patientCount}
            setLoading={showLoadingShield}
            onComplete={onStepResult}
            onError={showError}
            reset={reset}
            slotTaken={slotTaken}
            dropinMode={dropinMode}
            t={t}
            productSlugs={appointment.productSlugs}
          />
        )

      case VIEWS.otp:
        return (
          <Otp
            renderAppointmentSummary={renderAppointmentSummary}
            onComplete={onStepResult}
            setLoading={showLoadingShield}
            onError={showError}
            locale={locale}
            reset={reset}
            t={t}
          />
        )

      case VIEWS.existingAppointment:
        return (
          <ExistingAppointments
            appointments={existingAppointments}
            onComplete={onStepResult}
            onError={showError}
            reset={reset}
            t={t}
          />
        )

      case VIEWS.WhoIsPaying:
        return (
          <WhoIsPaying
            t={t}
            onComplete={onStepResult}
            patients={patients}
            billableOrganizations={billableOrganizations}
            handleWhoIsPaying={handleWhoIsPaying}
            renderAppointmentSummary={renderAppointmentSummary}
          />
        )

      case VIEWS.user:
        return (
          <UserForm
            who={who}
            existingUser={user}
            onComplete={onStepResult}
            hasMultipleCustomers={hasMultipleCustomers}
            hasOrganization={!!organization}
            onError={showError}
            reset={reset}
            t={t}
            productSlugs={productSlugs || null}
            renderAppointmentSummary={renderAppointmentSummary}
          />
        )

      case VIEWS.patients:
        return (
          <PatientForm
            who={who}
            userId={user.id}
            patientCount={patientCount}
            onComplete={onStepResult}
            onError={showError}
            reset={reset}
            t={t}
            renderAppointmentSummary={renderAppointmentSummary}
          />
        )

      case VIEWS.consent:
        return appointmentPatient ? (
          <>
            <ConsentForm
              user={appointment.appointmentCreator}
              appointment={appointment}
              onComplete={onStepResult}
              patientId={appointmentPatient.patient_id}
              appointmentPatient={appointmentPatient}
              t={t}
            />
          </>
        ) : (
          <>
            <ConsentOverview
              user={user}
              appointment={appointment}
              onComplete={onStepResult}
              t={t}
              renderAppointmentSummary={renderAppointmentSummary}
            />
          </>
        )

      case VIEWS.confirmation:
        return (
          <>
            <Confirmation
              autoRestart={kioskMode}
              appointment={appointment}
              patients={patients}
              onError={showError}
              onUpdate={onUpdate}
              reset={reset}
              comingFromSmsLink={comingFromSmsLink}
              t={t}
            />
          </>
        )

      default:
        return `Cannot render ${view}`
    }
  }

  const renderAppointmentSummary = () => (
    <AppointmentSummary
      parentOfAppointment={appointment.serviceCenter || booking}
      productNames={appointment.products?.map((p) => p.publicName)}
      organization={organization}
      t={t}
    />
  )

  return (
    <article className="appointment-wizard">
      <ShieldedSpinner
        show={loading?.status}
        message={loading?.message}
      />
      <div className="content">
        <LanguageSwitcher
          className={`my-2 w-100 d-flex ${
            view !== VIEWS.who
              ? 'justify-content-end'
              : 'justify-content-start'
          }`}
          style
          onChange={setLocale}
          defaultLocale={locale}
          flags={assetRootUrl.flags}
        />
        {renderView()}
      </div>
      {error && <ErrorView error={error} reset={reset} t={t} />}
    </article>
  )
}

AppointmentWizard.propTypes = {
  serviceCenter: serviceCenterShape,
  booking: PropTypes.object,
  phoneNumber: PropTypes.string,
  billableOrganizations: PropTypes.arrayOf(PropTypes.object) || [],
  productSlugs: PropTypes.arrayOf(PropTypes.string),
  kioskMode: PropTypes.bool.isRequired,
  assetRootUrl: PropTypes.object.isRequired,
  linkAppointment: PropTypes.object,
  appointmentPatient: PropTypes.object,
  organization: PropTypes.object,
}

export default AppointmentWizard
