import gql from '../../api/graphql'
import { MODE } from './AppointmentWizard'

const netError = (e) => ({
  status: e.request?.status,
  netError: true,
  errors: e,
})

const appointmentAvailabilityCalendar = async (
  serviceCenterId,
  bookingId,
  date,
  patientCount,
  productSlugs,
) => {
  const now = new Date()

  // toISOString doesn't compensate for TZ offset
  const tzoffset = now.getTimezoneOffset() * 60000
  const localISOTime = new Date(date - tzoffset)
    .toISOString()
    .substr(0, 10)

  // const hourFrom =
  //   now.toDateString() != date.toDateString()
  //     ? '08:00'
  //     : now.toTimeString().substr(0, 5)

  try {
    const response = await gql(
      `query {
        appointmentAvailabilityCalendar(
          ${
            serviceCenterId
              ? `serviceCenterIds: [${serviceCenterId}]`
              : ''
          },
          ${bookingId ? `bookingId: ${bookingId}` : ''},
          dateFrom: "${localISOTime}",
          dateTo: "${localISOTime}",
          requiredCapacity: ${patientCount},
          productSlugs: [${productSlugs
            .map((el) => `"${el}"`)
            .join(',')}],
          hourFrom: "06:00",
          hourTo: "23:00"
        ) {
          ${
            serviceCenterId
              ? `serviceCenter {
            id
            name
            title
            street
            zip
            city
            googlePlaceId
            operationType
            lat
            lng
            }`
              : ''
          }
          bookingId
          availability
        }
      }`,
    )
    return {
      data: response.data?.data.appointmentAvailabilityCalendar,
    }
  } catch (e) {
    return netError(e)
  }
}

const fetchAppointment = async (appointment) => {
  const response = await gql(
    `query {
        appointment(
         id: ${appointment.id}
        ) {
          id
          date
          hour
          code
          ${
            appointment.serviceCenter
              ? `serviceCenter {
            id
            name
            title
            street
            zip
            city
            googlePlaceId
            operationType
            lat
            lng
            }`
              : ''
          }
          ${
            appointment.booking
              ? `booking {
            id
            address
            zip
            city
            lat
            lng
            }`
              : ''
          }
          products {
            slug
            consentFields{
              description
              position
              slug
            }
          }
          appointmentCreator {
            id
          }
          appointmentsPatients {
            name
            id
            patientId
          }
          order {
            id
            totalPrice
            lineItems {
              id
              quantity
              defaultPrice
              netPrice
              coupon {
                code
              }
              product {
                id
                publicName
                name
                price
                type
                slug
                productCategory {
                  name
                }
              }
            }
          }
        }
      }`,
  )

  return response.data?.data.appointment
}

const createAppointment = async (
  appointment,
  patients,
  hasMultipleCustomers = false,
  companyName,
  whoIsPaying,
  consentForEmployerToSeeEmployeeName = true,
) => {
  const bookingMode = appointment.hour.match(/\d\d:\d\d/)
    ? MODE.NORMAL
    : appointment.hour
  if (!patients?.length)
    throw Error('Cannot create appointment without patients')

  const patientIds = patients.join(',')
  const patientsAttributes = patients[0]

  try {
    const response = await gql(
      `mutation {
        createAppointment(
          ${
            appointment.serviceCenter
              ? `serviceCenterId: ${appointment.serviceCenter.id}`
              : ''
          },
          ${
            appointment.booking
              ? `bookingId: ${appointment.booking?.id}`
              : ''
          },
          patientAttributes: [
            {
              patientId: ${patientsAttributes.id || patientIds},
              consentForEmployerToSeeEmployeeName: ${consentForEmployerToSeeEmployeeName}
              ${
                hasMultipleCustomers && companyName && whoIsPaying
                  ? `whoIsPaying: "${whoIsPaying}",
                   companyName: "${companyName}"`
                  : hasMultipleCustomers &&
                    !companyName &&
                    whoIsPaying
                  ? `whoIsPaying: "${whoIsPaying}"`
                  : ''
              }
            }
          ],
          date: "${appointment.date}",
          hour: "${appointment.hour}",
          productSlugs: ${JSON.stringify(appointment.productSlugs)},
          ${
            !hasMultipleCustomers
              ? `patientIds: [${patientIds}],`
              : ''
          },
          mode: ${bookingMode}
        ) {
          id
          date
          hour
          code
          ${
            appointment.serviceCenter
              ? `serviceCenter {
            id
            name
            title
            street
            zip
            city
            googlePlaceId
            operationType
            lat
            lng
            }`
              : ''
          }
          ${
            appointment.booking
              ? `booking {
            id
            address
            zip
            city
            lat
            lng
            }`
              : ''
          }
          products {
            slug
            consentFields{
              description
              position
              slug
            }
          }
          appointmentsPatients {
            name
            id
            patientId
          }
          products {
            slug
            consentFields{
              description
              slug
            }
          }
          order {
            id
            totalPrice
            lineItems {
              id
              quantity
              defaultPrice
              netPrice
              coupon {
                code
              }
              product {
                id
                publicName
                name
                price
                type
                slug
                productCategory {
                  name
                }
              }
            }
          }
        }
      }`,
    )

    return {
      data: response.data?.data.createAppointment,
    }
  } catch (e) {
    return netError(e)
  }
}

const updateAppointment = async (user, appointment) => {
  if (!appointment.id) {
    throw Error('Cannot update appointment without id')
  }
  const attributes = []
  if (appointment.options) {
    appointment.options.forEach((option) =>
      attributes.push(`${option.slug} : ${option.value}`),
    )
  }
  const stringifiedOptions = attributes.join(',')

  try {
    const response = await gql(
      `mutation {
        updateAppointment(
          userId: ${user.id},
          id: ${appointment.id},
          ${!!appointment.code ? `code: "${appointment.code}"` : ''},
          date: "${appointment.date}",
          hour: "${appointment.hour}",
          ${
            appointment.patientId
              ? `patientAttributes: {
            patientId: ${parseInt(appointment.patientId)},
            options: {${stringifiedOptions}}
          }`
              : ''
          }
        ) {
          id
          date
          hour
          code
          order {
            id
            totalPrice
            lineItems {
              id
              quantity
              defaultPrice
              netPrice
              coupon {
                code
              }
              product {
                id
                publicName
                name
                price
                type
                slug
                productCategory {
                  name
                }
              }
            }
          }
          appointmentsPatients{
            id
            name
            options
          }
          serviceCenter {
            id
            name
            title
            street
            zip
            city
            lat
            lng
            directions
            operationType
          }
          booking {
            id
            address
            zip
            city
            lat
            lng
          }
          products {
            id
            name
            publicName
            price
            description
            imageUrl
            slug
            vatRate
            requireShipping
            requireHousecall
            requireDigital
            createdAt
            updatedAt
            type
            consentFields{
              description
              position
              slug
            }
          }
        }
      }`,
    )
    return {
      data: response.data?.data.updateAppointment,
    }
  } catch (e) {
    return netError(e)
  }
}

const updateUser = async (user) => {
  try {
    const response = await gql(
      `mutation {
        updateUser(
          id: ${user.id},
          name: "${user.name}",
          email: "${user.email}",
          nationalId: ${
            user.nationalId ? `"${user.nationalId}"` : null
          },
          marketingConsent: ${user.marketingConsent}
        ) {
          id
          name
          email
          nationalId
          patient {
            id
          }
        }
      }`,
    )
    return {
      data: response.data?.data.updateUser,
    }
  } catch (e) {
    return netError(e)
  }
}

const sendOtpCode = async (phoneNumber) => {
  try {
    const response = await gql(
      `mutation {
          newOtpSession(
            phoneNumber: "${phoneNumber}",
            userAgnostic: false
          ) {
            otpCode
          }
        }
      `,
    )

    return {
      data: response.data?.data.newOtpSession,
      errors: response.data?.errors,
    }
  } catch (e) {
    return netError(e)
  }
}

const verifyOtpCode = async (phoneNumber, code, locale) => {
  try {
    const response = await gql(
      `mutation {
        verifyOtpSession(
          phoneNumber: "${phoneNumber}",
          code: "${code}",
          locale: ${locale.toUpperCase()}
        ) {
          id
          user {
            id
            name
            email
            nationalId
            marketingConsent
          }
        }
      }`,
    )

    return {
      data: response.data?.data.verifyOtpSession,
    }
  } catch (e) {
    return netError(e)
  }
}

const cancelAppointment = async (appointmentId) => {
  try {
    const response = await gql(
      `mutation {
        cancelAppointment(
          id: ${appointmentId}
        ) {
          id
          deletedAt
        }
      }`,
    )

    return {
      data: response.data?.data.cancelAppointment,
    }
  } catch (e) {
    return netError(e)
  }
}

const fetchUpcomingAppointments = async (user) => {
  try {
    const response = await gql(
      `query {
        upcomingAppointments(
          userId: ${user.id}
        ){
          id
          date
          hour
          serviceCenter {
            id
            name
            title
            street
            zip
            city
            lat
            lng
            operationType
          }
          booking {
            id
            beginsAt
            lat
            lng
            address
            zip
            city
          }
          appointmentsPatients {
              id
              name
          }
          products {
            id
            name
            publicName
            price
            description
            imageUrl
            slug
            vatRate
            requireShipping
            requireHousecall
            requireDigital
            createdAt
            updatedAt
            type
          }
          order {
            id
            totalPrice
            lineItems {
              id
              quantity
              defaultPrice
              netPrice
              coupon {
                code
              }
              product {
                id
                publicName
                name
                price
                type
                slug
                productCategory {
                  name
                }
              }
            }
          }
        }
      }`,
    )

    return {
      data: response.data?.data.upcomingAppointments,
    }
  } catch (e) {
    return netError(e)
  }
}

const createPatients = async (userId, patients) => {
  const patientData = patients
    .map((p) => `{name: "${p.name}", nationalId: "${p.nationalId}"}`)
    .join(',')
  try {
    const response = await gql(
      `mutation {
        createPatients(
          userId: ${userId}
          patientsAttributes: [${patientData}]
        ) {
          id
        }
      }`,
    )

    return {
      data: response.data?.data.createPatients,
    }
  } catch (e) {
    return netError(e)
  }
}

const sendLinkWithSms = async (appointmentPatientId, phoneNumber) => {
  try {
    const response = await gql(`
    mutation {
      sendAppointmentFormLink(appointmentPatientId: ${appointmentPatientId}, phoneNumbers: ["${phoneNumber}"])
    }
    `)
    return response.data.data.sendAppointmentFormLink
  } catch (e) {
    return e
  }
}

const getPriceFromOrder = async (productId, quantity) => {
  const searchOptions = `lineItemAttributes: [
        { v2ProductId: ${productId}, quantity: ${quantity} },
      ]`

  const query = `query {
        v2OrderPrice(${searchOptions}) {
          totalNetPrice
          lineItems
        } }`

  try {
    const response = await gql(query)
    return {
      data: response.data?.data.v2OrderPrice,
    }
  } catch (e) {
    return netError(e)
  }
}

const availableHours = async (
  zip,
  startDate,
  endDate,
  bookingDuration,
) => {
  try {
    const response = await gql(
      `query {
        availableHours(
          zip: "${zip}"
          startDate: "${startDate}"
          endDate: "${endDate}"
          bookingDuration: ${bookingDuration}
        ) {
            availableBookings {
              beginsAt
              endsAt
              date
              spProductsNames
              spTypesName
              serviceProvider {
                id
                fullName
              }
            }
          }
        }`,
    )
    return {
      data: response.data?.data.availableHours,
    }
  } catch (e) {
    return netError(e)
  }
}

const confirmAppointment = async (appointmentId) => {
  try {
    const response = await gql(`
    mutation {
      confirmAppointment(id:"${appointmentId}"){
          id
          confirmed
      }
    }
    `)
    return response.data.data.confirmAppointment
  } catch (e) {
    return netError(e)
  }
}

const logOut = async () => {
  const response = await gql(`
  mutation {
    logout
  }
`)
  return response
}

export {
  appointmentAvailabilityCalendar,
  sendOtpCode,
  verifyOtpCode,
  updateUser,
  createPatients,
  fetchUpcomingAppointments,
  cancelAppointment,
  createAppointment,
  updateAppointment,
  sendLinkWithSms,
  availableHours,
  fetchAppointment,
  getPriceFromOrder,
  confirmAppointment,
  logOut,
}
